import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import IconShoppingBag from '../../components/Icon/IconShoppingBag';
import IconTag from '../../components/Icon/IconTag';
import IconCreditCard from '../../components/Icon/IconCreditCard';
import { Tab } from '@headlessui/react';
import Dropdown from '../../components/Dropdown';
import IconHorizontalDots from '../../components/Icon/IconHorizontalDots';
import { IRootState } from '../../store';
import { useSelector } from 'react-redux';
import IconCode from '../Icon/IconCode';
import IconHome from '../Icon/IconHome';
import IconUser from '../Icon/IconUser';
import IconPhone from '../Icon/IconPhone';
import MpesaUploadComponent from '../upload/MpesaUploadComponent';
import FamilyBankUploadComponent from '../upload/FamilyBankUploadComponent';
import { AccessBankUploadComponent } from '../upload/AccessBankUploadComponent';
import { PrimeBankUploadComponent } from '../upload/PrimeBankUploadComponent';
import AirtelUploadComponent from '../upload/AirtelUploadComponent';
import CrbUploadComponent from '../upload/CrbUploadComponent';

interface Profile {
    thumb: String;
    name: String;
    email: String;
    creditScore: number;
    totalAccounts: number;
    totalTransactions: number;
    regularIncomeAmount: number;
    averageExpenditure: number;
    totalCreditRequests: number;
    defaultRate: number;
    averageAccountBalance: number;
    profileID: string;
    profileIdentifier: String;
    identifierType: String;
    id_number: String;
    sources: any;
    credit_score: any;
}

interface ProfileBreakdownComponentProps {
    profile: Profile;
}
const TransactionProfileBreakdown: React.FC<ProfileBreakdownComponentProps> = ({ profile }) => {
    const isRtl = useSelector((state: IRootState) => state.themeConfig.rtlClass) === 'rtl' ? true : false;
    const user = useSelector((state: any) => state.user);
    const [selectedOption, setSelectedOption] = useState('');

    const handleSelectChange = (event: any) => {
        setSelectedOption(event.target.value);
    };

    const getCreditClass = (score: number) => {
        if (score < 500) {
            return 'text-danger';
        } else if (score > 500 && score < 600) {
            return 'text-warning';
        } else {
            return 'text-primary';
        }
    };

    const onFileUploaded = async (serverID: string) => {
        console.log('serverID: ', serverID);
    };
    return (
        <div className="pt-5">
            <div className="panel mb-2">
                {/* <div className="flex items-center justify-between mb-2">
                    <h5 className="font-semibold text-lg dark:text-white-light">Profile</h5>
                    <Link to="/users/user-account-settings" className="ltr:ml-auto rtl:mr-auto btn btn-primary p-2 rounded-full">
                        <span className="font-bold uppercase px-2">E</span>
                    </Link>
                </div> */}
                <div className="mb-2">
                    <div className="flex flex-col justify-center items-center">
                        <p className="font-semibold text-primary text-xl">{profile.id_number}</p>
                    </div>
                    <ul className="mt-2 flex flex-col font-semibold text-white-dark">
                        <li className="items-center gap-2 text-center">
                            <div className="font-bold mb-2 text-lg">Credit score</div>
                            <div className={'font-bold text-4xl ' + getCreditClass(profile.credit_score)}>{profile.credit_score}</div>
                        </li>
                        {/* <li className="items-center gap-2 text-center">{profile.totalAccounts} accounts found</li>
                        <li className="items-center gap-2 text-center">{profile.totalTransactions} transactions</li>
                        <li className="items-center gap-2 text-center">Cluster - E</li> */}
                    </ul>
                    <p className="text-white-light">Uploads found in the database</p>
                    <ul className="list-disc list-inside pl-2 mb-3">
                        {/* Mobile Money Section */}
                        {profile.sources?.mobileMoney?.length > 0 && (
                            <li>
                                <strong>Mobile Money:</strong> ({profile.sources.mobileMoney.length})
                            </li>
                        )}

                        {/* Bureau Section */}
                        {profile.sources?.bureau?.length > 0 && (
                            <li>
                                <strong>Bureau:</strong> ({profile.sources.bureau.length})
                            </li>
                        )}

                        {/* Bank Statements Section */}
                        {profile.sources?.bankStatements?.length > 0 && (
                            <li>
                                <strong>Bank Statements:</strong> ({profile.sources.bankStatements.length})
                            </li>
                        )}

                        {/* Fallback if no sources available */}
                        {!(profile.sources?.mobileMoney?.length > 0 || profile.sources?.bureau?.length > 0 || profile.sources?.bankStatements?.length > 0) && <li>No uploads available</li>}
                    </ul>
                </div>
            </div>
            <div className="panel" id="line">
                <div className="mb-5 flex items-center justify-between">
                    <h5 className="text-lg font-semibold dark:text-white-light">Data Upload</h5>
                </div>
                <div className="mb-5">
                    <Tab.Group>
                        <Tab.List className="mt-3 flex flex-wrap border-b border-white-light dark:border-[#191e3a]">
                            <Tab as={Fragment}>
                                {({ selected }) => (
                                    <button
                                        className={`${selected ? 'text-secondary !outline-none before:!w-full' : ''}
                                                    before:inline-block' relative -mb-[1px] flex items-center p-5 py-3 before:absolute before:bottom-0 before:left-0 before:right-0 before:m-auto before:h-[1px] before:w-0 before:bg-secondary before:transition-all before:duration-700 hover:text-secondary hover:before:w-full`}
                                    >
                                        <IconHome className="ltr:mr-2 rtl:ml-2" />
                                        Mobile Money
                                    </button>
                                )}
                            </Tab>
                            <Tab as={Fragment}>
                                {({ selected }) => (
                                    <button
                                        className={`${selected ? 'text-secondary !outline-none before:!w-full' : ''}
                                                    before:inline-block' relative -mb-[1px] flex items-center p-5 py-3 before:absolute before:bottom-0 before:left-0 before:right-0 before:m-auto before:h-[1px] before:w-0 before:bg-secondary before:transition-all before:duration-700 hover:text-secondary hover:before:w-full`}
                                    >
                                        <IconHome className="ltr:mr-2 rtl:ml-2" />
                                        Banking Institution
                                    </button>
                                )}
                            </Tab>
                            <Tab as={Fragment}>
                                {({ selected }) => (
                                    <button
                                        className={`${selected ? 'text-secondary !outline-none before:!w-full' : ''}
                                                    before:inline-block' relative -mb-[1px] flex items-center p-5 py-3 before:absolute before:bottom-0 before:left-0 before:right-0 before:m-auto before:h-[1px] before:w-0 before:bg-secondary before:transition-all before:duration-700 hover:text-secondary hover:before:w-full`}
                                    >
                                        <IconHome className="ltr:mr-2 rtl:ml-2" />
                                        Bureau
                                    </button>
                                )}
                            </Tab>
                            {/* <Tab as={Fragment}>
                                {({ selected }) => (
                                    <button
                                        className={`${selected ? 'text-secondary !outline-none before:!w-full' : ''}
                                                before:inline-block' relative -mb-[1px] flex items-center p-5 py-3 before:absolute before:bottom-0 before:left-0 before:right-0 before:m-auto before:h-[1px] before:w-0 before:bg-secondary before:transition-all before:duration-700 hover:text-secondary hover:before:w-full`}
                                    >
                                        <IconUser className="w-5 h-5 ltr:mr-2 rtl:ml-2" />
                                        Family bank
                                    </button>
                                )}
                            </Tab>
                            <Tab as={Fragment}>
                                {({ selected }) => (
                                    <button
                                        className={`${selected ? 'text-secondary !outline-none before:!w-full' : ''}
                                                before:inline-block' relative -mb-[1px] flex items-center p-5 py-3 before:absolute before:bottom-0 before:left-0 before:right-0 before:m-auto before:h-[1px] before:w-0 before:bg-secondary before:transition-all before:duration-700 hover:text-secondary hover:before:w-full`}
                                    >
                                        <IconPhone className="ltr:mr-2 rtl:ml-2" />
                                        Airtel money
                                    </button>
                                )}
                            </Tab> */}
                        </Tab.List>
                        <Tab.Panels>
                            <Tab.Panel>
                                <select className="form-select text-white-dark mt-5" onChange={handleSelectChange} value={selectedOption}>
                                    <option disabled selected value="">
                                        Choose...
                                    </option>
                                    <option value="mpesa">Mpesa Statement</option>
                                    <option value="airtel">Airtel Money</option>
                                    <option value="momo">Momo Statement</option>
                                </select>
                                {selectedOption === 'mpesa' && (
                                    <div className="active pt-5">
                                        <h4 className="mb-4 text-2xl font-semibold">Upload your Mpesa statement here</h4>
                                        <div className="">
                                            <MpesaUploadComponent customer={profile.id_number} onFileUpload={onFileUploaded} user={user.user} />
                                        </div>
                                    </div>
                                )}
                                {selectedOption === 'airtel' && (
                                    <div className="active pt-5">
                                        <h4 className="mb-4 text-2xl font-semibold">Upload your Airtel statement here</h4>
                                        <div className="">
                                            <AirtelUploadComponent onFileUpload={onFileUploaded} user={user.user} />
                                        </div>
                                    </div>
                                )}
                                {selectedOption === 'momo' && (
                                    <div className="active pt-5">
                                        <h4 className="mb-4 text-2xl font-semibold">Upload your Momo statement here</h4>
                                        <div className="">
                                            <AirtelUploadComponent onFileUpload={onFileUploaded} user={user.user} />
                                        </div>
                                    </div>
                                )}
                            </Tab.Panel>
                            <Tab.Panel>
                                <select className="form-select text-white-dark mt-5" onChange={handleSelectChange} value={selectedOption}>
                                    <option disabled selected value="">
                                        Choose...
                                    </option>
                                    <option value="family">Family Bank</option>
                                    <option value="equity">Equity</option>
                                    <option value="kcb">KCB</option>
                                    <option value="access">Access Bank</option>
                                    <option value="prime">Prime Bank</option>
                                </select>
                                {selectedOption === 'family' && (
                                    <div className="active pt-5">
                                        <h4 className="mb-4 text-2xl font-semibold">Upload your Family Bank statement here</h4>
                                        <div className="">
                                            <FamilyBankUploadComponent onFileUpload={onFileUploaded} user={user.user} />
                                        </div>
                                    </div>
                                )}
                                {selectedOption === 'access' && (
                                    <div className="active pt-5">
                                        <h4 className="mb-4 text-2xl font-semibold">Upload your Access Bank statement here</h4>
                                        <div className="">
                                            <AccessBankUploadComponent customer={profile.id_number} onFileUpload={onFileUploaded} user={user.user} />
                                        </div>
                                    </div>
                                )}
                                {selectedOption === 'prime' && (
                                    <div className="active pt-5">
                                        <h4 className="mb-4 text-2xl font-semibold">Upload your Prime Bank statement here</h4>
                                        <div className="">
                                            <PrimeBankUploadComponent customer={profile.id_number} onFileUpload={onFileUploaded} user={user.user} />
                                        </div>
                                    </div>
                                )}
                                {/* {selectedOption === 'equity' && (
                                    <div className="active pt-5">
                                        <h4 className="mb-4 text-2xl font-semibold">Upload your Equity statement here</h4>
                                    </div>
                                )}
                                {selectedOption === 'kcb' && (
                                    <div className="active pt-5">
                                        <h4 className="mb-4 text-2xl font-semibold">Upload your KCB statement here</h4>
                                    </div>
                                )} */}
                            </Tab.Panel>
                            <Tab.Panel>
                                <div className="active pt-5">
                                    <h4 className="mb-4 text-2xl font-semibold">Upload your crb statement here</h4>
                                    <div className="">
                                        <CrbUploadComponent customer={profile.id_number} onFileUpload={onFileUploaded} user={user.user} />
                                    </div>
                                </div>
                            </Tab.Panel>

                            {/* <Tab.Panel>
                                <div className="active pt-5">
                                    <h4 className="mb-4 text-2xl font-semibold">Upload your Airtel statement here</h4>
                                    <div className="">
                                        <AirtelUploadComponent onFileUpload={onFileUploaded} user={user.user} />
                                    </div>
                                </div>
                            </Tab.Panel> */}
                            <Tab.Panel>Disabled</Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </div>
    );
};

export default TransactionProfileBreakdown;
