import React from 'react';
import { Link } from 'react-router-dom';
import IconShoppingBag from '../../components/Icon/IconShoppingBag';
import IconTag from '../../components/Icon/IconTag';
import IconCreditCard from '../../components/Icon/IconCreditCard';

import Dropdown from '../../components/Dropdown';
import IconHorizontalDots from '../../components/Icon/IconHorizontalDots';
import { IRootState } from '../../store';
import { useSelector } from 'react-redux';
import IconSearch from '../Icon/IconSearch';

interface Profile {
    thumb: String;
    name: String;
    email: String;
    creditScore: number;
    totalAccounts: number;
    totalTransactions: number;
    regularIncomeAmount: number;
    averageExpenditure: number;
    totalCreditRequests: number;
    defaultRate: number;
    averageAccountBalance: number;
    profileID: String;
    profileIdentifier: String;
    identifierType: String;
    credit_score: number;
    id_number: string;
}

interface ProfileBreakdownComponentProps {
    profile: Profile;
}
const ProfileBreakdown: React.FC<ProfileBreakdownComponentProps> = ({ profile }) => {
    const isRtl = useSelector((state: IRootState) => state.themeConfig.rtlClass) === 'rtl' ? true : false;

    const getCreditClass = (score: number) => {
        if (score < 500) {
            return 'text-danger';
        } else if (score > 500 && score < 600) {
            return 'text-warning';
        } else {
            return 'text-primary';
        }
    };
    return (
        <div className="">
            <div className="w-full mb-5">
                <div className="panel lg:col-span-1 xl:col-span-2">
                    <div className="flex items-center justify-between mb-5">
                        <h5 className="font-semibold text-lg dark:text-white-light">Profile</h5>
                        <Link to="/users/user-account-settings" className="ltr:ml-auto rtl:mr-auto btn btn-primary p-2 rounded-full">
                            <span className="font-bold uppercase px-2">E</span>
                        </Link>
                    </div>
                    <div className="mb-5">
                        <div className="flex flex-col justify-center items-center">
                            {/* <img src={'/assets/images/' + profile.thumb} alt="img" className="w-24 h-24 rounded-full object-cover  mb-5" /> */}
                            <p className="font-semibold text-gray-400 mb-5 text-3xl">{profile.id_number}</p>
                            <Link to={'/apps/profile-breakdown?profileID=' + profile.id_number} type="button" className="btn btn-outline-info mt-2 btn-sm">
                                Expand profile
                            </Link>
                        </div>
                        <ul className="mt-5 flex flex-col max-w-[160px] m-auto space-y-4 font-semibold text-white-dark">
                            <li className="items-center gap-2 text-center">
                                <div className="font-bold mb-2">Credit score</div>
                                <div className={'font-bold text-4xl ' + getCreditClass(profile.credit_score)}>{profile.credit_score}</div>
                            </li>
                            <li className="items-center gap-2 text-center">{profile.totalAccounts} accounts found</li>
                            <li className="items-center gap-2 text-center">{profile.totalTransactions} transactions</li>
                        </ul>
                        {/* <div className="grid grid-cols-2 gap-4 mt-6">
                            <div className="border border-[#ebedf2] rounded dark:bg-[#1b2e4b] dark:border-0">
                                <div className="flex items-center justify-between p-4 py-2">
                                    <div className="grid place-content-center w-9 h-9 rounded-md bg-secondary-light dark:bg-secondary text-secondary dark:text-secondary-light">
                                        <IconShoppingBag />
                                    </div>
                                    <div className="ltr:ml-4 rtl:mr-4 flex items-start justify-between flex-auto font-semibold">
                                        <h6 className="text-white-dark text-[13px] dark:text-white-dark">
                                            Income
                                            <span className="block text-base text-[#515365] dark:text-white-light">$ {profile.regularIncomeAmount}</span>
                                        </h6>
                                        <p className="ltr:ml-auto rtl:mr-auto text-secondary">90%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border border-[#ebedf2] rounded dark:bg-[#1b2e4b] dark:border-0">
                                <div className="flex items-center justify-between p-4 py-2">
                                    <div className="grid place-content-center w-9 h-9 rounded-md bg-info-light dark:bg-info text-info dark:text-info-light">
                                        <IconTag />
                                    </div>
                                    <div className="ltr:ml-4 rtl:mr-4 flex items-start justify-between flex-auto font-semibold">
                                        <h6 className="text-white-dark text-[13px] dark:text-white-dark">
                                            Avg. Account Balance
                                            <span className="block text-base text-[#515365] dark:text-white-light">$ {profile.averageAccountBalance}</span>
                                        </h6>
                                        <p className="ltr:ml-auto rtl:mr-auto text-info">65%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border border-[#ebedf2] rounded dark:bg-[#1b2e4b] dark:border-0">
                                <div className="flex items-center justify-between p-4 py-2">
                                    <div className="grid place-content-center w-9 h-9 rounded-md bg-warning-light dark:bg-warning text-warning dark:text-warning-light">
                                        <IconCreditCard />
                                    </div>
                                    <div className="ltr:ml-4 rtl:mr-4 flex items-start justify-between flex-auto font-semibold">
                                        <h6 className="text-white-dark text-[13px] dark:text-white-dark">
                                            Avg. Expenditure
                                            <span className="block text-base text-[#515365] dark:text-white-light">$ {profile.averageExpenditure}</span>
                                        </h6>
                                        <p className="ltr:ml-auto rtl:mr-auto text-warning">80%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border border-[#ebedf2] rounded dark:bg-[#1b2e4b] dark:border-0">
                                <div className="flex items-center justify-between p-4 py-2">
                                    <div className="grid place-content-center w-9 h-9 rounded-md bg-warning-light dark:bg-warning text-warning dark:text-warning-light">
                                        <IconCreditCard />
                                    </div>
                                    <div className="ltr:ml-4 rtl:mr-4 flex items-start justify-between flex-auto font-semibold">
                                        <h6 className="text-white-dark text-[13px] dark:text-white-dark">
                                            Credit requests
                                            <span className="block text-base text-[#515365] dark:text-white-light">{profile.totalCreditRequests}</span>
                                        </h6>
                                        <p className="ltr:ml-auto rtl:mr-auto text-warning">80%</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <ul className="mt-7 flex items-center justify-center gap-2">
                    <li>
                        <button className="btn btn-info flex items-center justify-center rounded-full w-10 h-10 p-0">
                            <IconTwitter className="w-5 h-5" />
                        </button>
                    </li>
                    <li>
                        <button className="btn btn-danger flex items-center justify-center rounded-full w-10 h-10 p-0">
                            <IconDribbble />
                        </button>
                    </li>
                    <li>
                        <button className="btn btn-dark flex items-center justify-center rounded-full w-10 h-10 p-0">
                            <IconGithub />
                        </button>
                    </li>
                </ul> */}
                    </div>
                </div>
                {/* <div className="panel lg:col-span-1 xl:col-span-2">
                    <div className="mb-5">
                        <h5 className="font-semibold text-lg dark:text-white-light">Summary</h5>
                    </div>
                    <div className="space-y-4">
                        <div className="border border-[#ebedf2] rounded dark:bg-[#1b2e4b] dark:border-0">
                            <div className="flex items-center justify-between p-4 py-2">
                                <div className="grid place-content-center w-9 h-9 rounded-md bg-secondary-light dark:bg-secondary text-secondary dark:text-secondary-light">
                                    <IconShoppingBag />
                                </div>
                                <div className="ltr:ml-4 rtl:mr-4 flex items-start justify-between flex-auto font-semibold">
                                    <h6 className="text-white-dark text-[13px] dark:text-white-dark">
                                        Income
                                        <span className="block text-base text-[#515365] dark:text-white-light">$ {profile.regularIncomeAmount}</span>
                                    </h6>
                                    <p className="ltr:ml-auto rtl:mr-auto text-secondary">90%</p>
                                </div>
                            </div>
                        </div>
                        <div className="border border-[#ebedf2] rounded dark:bg-[#1b2e4b] dark:border-0">
                            <div className="flex items-center justify-between p-4 py-2">
                                <div className="grid place-content-center w-9 h-9 rounded-md bg-info-light dark:bg-info text-info dark:text-info-light">
                                    <IconTag />
                                </div>
                                <div className="ltr:ml-4 rtl:mr-4 flex items-start justify-between flex-auto font-semibold">
                                    <h6 className="text-white-dark text-[13px] dark:text-white-dark">
                                        Avg. Account Balance
                                        <span className="block text-base text-[#515365] dark:text-white-light">$ {profile.averageAccountBalance}</span>
                                    </h6>
                                    <p className="ltr:ml-auto rtl:mr-auto text-info">65%</p>
                                </div>
                            </div>
                        </div>
                        <div className="border border-[#ebedf2] rounded dark:bg-[#1b2e4b] dark:border-0">
                            <div className="flex items-center justify-between p-4 py-2">
                                <div className="grid place-content-center w-9 h-9 rounded-md bg-warning-light dark:bg-warning text-warning dark:text-warning-light">
                                    <IconCreditCard />
                                </div>
                                <div className="ltr:ml-4 rtl:mr-4 flex items-start justify-between flex-auto font-semibold">
                                    <h6 className="text-white-dark text-[13px] dark:text-white-dark">
                                        Avg. Expenditure
                                        <span className="block text-base text-[#515365] dark:text-white-light">$ {profile.averageExpenditure}</span>
                                    </h6>
                                    <p className="ltr:ml-auto rtl:mr-auto text-warning">80%</p>
                                </div>
                            </div>
                        </div>
                        <div className="border border-[#ebedf2] rounded dark:bg-[#1b2e4b] dark:border-0">
                            <div className="flex items-center justify-between p-4 py-2">
                                <div className="grid place-content-center w-9 h-9 rounded-md bg-warning-light dark:bg-warning text-warning dark:text-warning-light">
                                    <IconCreditCard />
                                </div>
                                <div className="ltr:ml-4 rtl:mr-4 flex items-start justify-between flex-auto font-semibold">
                                    <h6 className="text-white-dark text-[13px] dark:text-white-dark">
                                        Credit requests
                                        <span className="block text-base text-[#515365] dark:text-white-light">{profile.totalCreditRequests}</span>
                                    </h6>
                                    <p className="ltr:ml-auto rtl:mr-auto text-warning">80%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default ProfileBreakdown;
