// FilePondComponent.tsx

import React, { useRef } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css'; // Import FilePond styles
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { User } from '../../types';

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImagePreview);

interface FilePondComponentProps {
    onFileUpload: (serverID: string) => void;
    user: User;
}

const FamilyBankUploadComponent: React.FC<FilePondComponentProps> = ({ onFileUpload, user }) => {
    //console.log('user: ', user);
    const pondRef = useRef<FilePond>();

    // Function to handle file upload
    const handleInit = () => {
        console.log('FilePond instance has initialized');
    };

    // Function to handle file upload progress
    const handleProgress = (file: any, progress: any) => {
        console.log('File upload progress:', progress);
    };

    // Function to handle file upload success
    const handleFileSuccess = (file: any) => {
        const serverID = file.serverId;
        console.log('File upload success:', file);
        console.log('get server id: ', serverID);
        onFileUpload(serverID);
    };

    // Function to handle file upload error
    const handleFileError = (file: any, error: any) => {
        console.error('File upload error:', error);
    };

    const onAddFile = async (error: any, file: any) => {
        console.log('add file: ', file);
    };

    return (
        <FilePond
            chunkUploads={true}
            chunkForce={true}
            onaddfile={(error, file) => onAddFile(error, file)}
            maxFiles={1}
            chunkSize={2000000}
            server={{
                url: 'http://localhost:3000/v1/upload/historical-data',
                headers: {
                    user: user.id,
                },
            }}
            oninit={() => handleInit()}
            onprocessfileprogress={handleProgress}
            onprocessfile={(error, file) => (error ? handleFileError(file, error) : handleFileSuccess(file))}
            acceptedFileTypes={['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
        />
    );
};

export default FamilyBankUploadComponent;
